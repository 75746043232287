import { render, staticRenderFns } from "./DataDictionary.vue?vue&type=template&id=51dcd8d8&scoped=true"
import script from "./DataDictionary.vue?vue&type=script&lang=js"
export * from "./DataDictionary.vue?vue&type=script&lang=js"
import style0 from "./DataDictionary.vue?vue&type=style&index=0&id=51dcd8d8&prod&lang=stylus&scoped=true"
import style1 from "./DataDictionary.vue?vue&type=style&index=1&id=51dcd8d8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51dcd8d8",
  null
  
)

export default component.exports